import React, { createContext, useReducer, Dispatch } from "react";
import { linkReducer, LinkActions, LinkPageType } from "./linkData.reducer";
import { switchPageReducer, SwitchPageActions, SwitchPageType } from "./switchPage.reducer";
import { resultReducer, ResultActions, ResultType, EditSessionPayloadId } from "./result.reducer";
import {
  formReducer,
  FormPageType,
  FormActions,
  BlockRanges,
  Assignment,
  SelectedSessionsBySectionPayload,
} from "./formData.reducer";
import { Session } from "@models/ISchema";

export type InitialStateType = {
  link: LinkPageType;
  page: SwitchPageType;
  form: FormPageType;
  result: ResultType;
};

export const initialState: InitialStateType = {
  link: null,
  page: {
    active: {
      form: true,
      results: false,
      advanceSearchFilterInstructor: true,
      advanceSearchInstructor: false,
      advanceSearchClassroom: false,
    },
    loading: false,
  },
  form: {
    blockingThreshold: null,
    selectedSessions: [],
    sessionsToCreate: [],
    cloneSelected: [],
    sessionsToDelete: {},
    editedSessions: null,
    savedSessions: [],
    assignmentSame: {
      blocks: true,
      instructors: true,
      classrooms: true,
      intervals: true,
    },
    assignmentEdited: {
      blocks: true,
      instructors: true,
      classrooms: true,
      intervals: true,
    },
    errors: [],
    selectedCreateSession: false,
    sessionFromLocationSelected: false,
    sessionRecommendationIdSelected: null,
    selectedSection: null,
    savedSessionsToCreateIds: {},
    removedWeekIdsBySessionId: {},
  },
  result: null,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<
    | LinkActions
    | SwitchPageActions
    | FormActions
    | Session
    | BlockRanges
    | Assignment
    | SelectedSessionsBySectionPayload
    | ResultActions
    | EditSessionPayloadId[]
  >;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ link, page, form, result }: InitialStateType, action: any) => ({
  link: linkReducer(link, action),
  page: switchPageReducer(page, action),
  form: formReducer(form, link, action),
  result: resultReducer(result, action),
});

const AppProvider: React.FC<{ children?: React.ReactNode | undefined }> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext };
