import { Link } from "@models/ISchema";
import { useMemo, useState } from "react";

interface UseLinksStateProps {
  packageId: string;
}

const useLinksState = ({ packageId }: UseLinksStateProps) => {
  const [linksToDelete, setLinksToDelete] = useState<{ [id: string]: Link }>({});
  const [linksToAdd, setLinksToAdd] = useState<{ [id: string]: Link }>({});

  const assignmentsWithMultipleGroups = useMemo(() => {
    return Object.values(linksToAdd)?.filter(link => link?.groups?.length > 1)?.length;
  }, [linksToAdd]);

  const assignmentIds = useMemo(() => {
    return Object.values(linksToAdd)?.map(link => ({
      linkId: link.id,
      packageId,
    }));
  }, [linksToAdd]);

  const deletionsIds = useMemo(() => {
    return Object.values(linksToDelete)?.map(link => ({
      linkId: link.id,
      packageId,
    }));
  }, [linksToDelete]);

  const handleResetChanges = () => {
    setLinksToAdd({});
    setLinksToDelete({});
  };

  return {
    linksToAdd,
    linksToDelete,
    assignmentsWithMultipleGroups,
    assignmentIds,
    deletionsIds,
    setLinksToAdd,
    setLinksToDelete,
    handleResetChanges,
  };
};

export default useLinksState;
