import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { settingsQuery } from "../graphql/settings.query";
import { ContextEDH } from "@context/ContextEDH";
import { Types } from "@context/base.reducer";
import { BaseQuery, SettingFilterInput } from "@models/ISchema";
import { Setting } from "@models/ISchema";

enum settingsType {
  institutionCode = "Institution.code",
  mouseflowProjectId = "Mouseflow.projectId",
  groupsManagerHideColumnsFilter = "GroupsManager.hide_columns_filter",
  SchedulerEditorCreateGroupWithinLinkCreation = "SchedulerEditor.create_group_within_link_creation",
}

type SettingsObj = { [key: string]: Setting };

const getSettingsObj = (settings: Setting[]) => {
  const settingsObj: SettingsObj = {};

  settings.forEach(setting => {
    settingsObj[setting.config] = setting;
  });

  return settingsObj;
};

const useGetSettings = (): [{ data: boolean; error: boolean; loading: boolean }, () => void] => {
  const client = useApolloClient();
  const { dispatch } = useContext(ContextEDH);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const mouseflowConnect = (settingsObj: SettingsObj) => {
    const findInstitution = settingsObj[settingsType?.institutionCode];
    const findProjectId = settingsObj[settingsType?.mouseflowProjectId];

    if (findProjectId) {
      const projectId = findProjectId?.value.replaceAll('"', "");
      const mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src = `/mf/projects/${projectId}.js`;
      document.getElementsByTagName("head")[0].appendChild(mf);
      dispatch({ type: Types.SetMouseflowEnabled, payload: true });
    }

    if (findInstitution && findProjectId) {
      window["_mfq"] = window["_mfq"] || [];
      window["_mfq"].push(["tag", findInstitution?.value.replaceAll('"', "")]);
    }
  };

  const setGeneralSettings = (settingsObj: SettingsObj) => {
    const groupsManagerHideColumnsFilter =
      settingsObj[settingsType?.groupsManagerHideColumnsFilter];
    const shouldHideGroupsColumnsFilter = ["1", "true"].includes(
      groupsManagerHideColumnsFilter?.value || "",
    );

    const createGroupWithinLinkCreation =
      settingsObj?.[settingsType?.SchedulerEditorCreateGroupWithinLinkCreation];

    if (createGroupWithinLinkCreation?.value) {
      const value = JSON.parse(createGroupWithinLinkCreation?.value) as {
        enabled: boolean;
        creation_type: "MANDATORY | OPTIONAL";
      };
      const payload = value.enabled ? value.creation_type : "DISABLED";

      dispatch({
        type: Types.setCreateGroupWithinLinkCreation,
        payload: payload as "MANDATORY" | "OPTIONAL" | "DISABLED",
      });
    }

    dispatch({
      type: Types.setGroupManagerFilterEnabled,
      payload: !shouldHideGroupsColumnsFilter,
    });
  };

  const getSettings = async () => {
    setLoading(true);
    try {
      if (!localStorage.getItem("token")) return;

      const filter: SettingFilterInput = {
        fields: {
          config: {
            in: [
              settingsType.institutionCode,
              settingsType.mouseflowProjectId,
              settingsType.groupsManagerHideColumnsFilter,
              settingsType.SchedulerEditorCreateGroupWithinLinkCreation,
            ],
          },
        },
      };
      const variables = {
        query: settingsQuery,
        variables: {
          filter,
        },
      };
      const { data } = await client.query(variables);
      const baseQuery: BaseQuery = data?.base;
      const settings = baseQuery?.settings?.items;
      dispatch({ type: Types.SetSettings, payload: settings });

      if (settings?.length > 0) {
        const settingsObj = getSettingsObj(settings);

        mouseflowConnect(settingsObj);
        setGeneralSettings(settingsObj);
      }

      setResult(true);
      setError(false);
      setLoading(false);
    } catch (error) {
      console.error("[Settings]", error);
      dispatch({ type: Types.SetSettings, payload: [] });
      setResult(true);
      setError(true);
      setLoading(false);
    }
  };

  return [{ data: result, error, loading }, getSettings];
};

export default useGetSettings;
