import React, { useContext } from "react";
import { Card, Weekly } from "@foris/avocado-ui";
import { IWeeklyItem } from "@foris/avocado-ui";
import cx from "classnames";
import dayjs from "dayjs";
import { BookingContext } from "../../context/BookingContext";
import { format } from "../../utils";
import css from "./requestDays.module.scss";

const Days = () => {
  const { state } = useContext(BookingContext);
  const filterSelected = state?.search?.intervals?.filter(value => value.selected);
  const sorted = filterSelected?.sort((a, b) => {
    const dateA = new Date(a.startingDate)?.getTime();
    const dateB = new Date(b.startingDate)?.getTime();
    return dateA - dateB;
  });

  const dayByValue = (value: string) => {
    const days = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];
    return value ? days[value] : "";
  };

  const getDataWeek = () => {
    const dataIntervals: IWeeklyItem[] = state?.search?.intervals?.map(week => {
      return {
        id: week.id,
        label: week.value,
        disabled: false,
        state: week?.selected ? "active" : null,
        tooltip: {
          label: `Sem ${week.value} ${dayjs(week.startingDate)?.format("DD.MM.YYYY")} - ${dayjs(
            week.endingDate,
          )?.format("DD.MM.YYYY")}`,
        },
        onClick: () => null,
      };
    });
    return dataIntervals;
  };

  return (
    <Card.Simple className={cx(css.cardDays, "container-row", "row--block", "col_8", "col_md_12")}>
      <div className={cx(css.cardItem, "container-row")}>
        <div className="col_2 col_md_12">
          <label className={cx(css.cardItem_label)}>Recurrencia</label>
          <p className={cx(css.cardItem_value)}>
            {state?.search?.recurrence ? "Evento recurrente" : "Evento único"}
          </p>
        </div>
        {sorted?.length > 1 && (
          <>
            <div className="col_3 col_md_12">
              <label className={cx(css.cardItem_label)}>Inicio</label>
              <p className={cx(css.cardItem_value)}>
                <span>{`Semana ${sorted[0]?.value}`}</span>
                {` ${dayjs(sorted[0]?.startingDate).format("DD.MM.YYYY")} - ${dayjs(
                  sorted[0]?.endingDate,
                ).format("DD.MM.YYYY")}`}
              </p>
            </div>
            <div className="col_3 col_md_12">
              <label className={cx(css.cardItem_label)}>Fin</label>
              <p className={cx(css.cardItem_value)}>
                <span>{`Semana ${sorted[sorted.length - 1]?.value}`}</span>
                {` ${dayjs(sorted[sorted?.length - 1]?.startingDate).format(
                  "DD.MM.YYYY",
                )} - ${dayjs(sorted[sorted?.length - 1]?.endingDate).format("DD.MM.YYYY")}`}
              </p>
            </div>
          </>
        )}
      </div>
      <div className={cx(css.cardItem, "container-row")}>
        {state?.search?.dateTime?.map(date => {
          const find = date?.recurrence
            ? format?.DAYS_OPTIONS.find(value => value.value === date?.day?.value)
            : null;
          const dayNumber: string = new Date(date?.day?.value).getUTCDay().toString();
          const day = date?.recurrence ? find?.label.substring(0, 3) : dayByValue(dayNumber);
          const start = date?.start?.value?.label;
          const end = date?.end?.value?.label;
          const title = `${day}, ${start} - ${end}`;
          return (
            <div key={date?.index} className={cx(css.cardItem_days, "col_md_12")}>
              <label className={cx(css.cardItem_label)}>{`Día ${date?.index}`}</label>
              <p className={cx(css.cardItem_value)}>{title}</p>
            </div>
          );
        })}
      </div>
      {state?.search?.recurrence && (
        <Weekly className={css.cntSingleEvent_weekly} weeklyItems={getDataWeek()} />
      )}
    </Card.Simple>
  );
};

export default Days;
