import uuid from "uuid-random";
import { cloneDeep, size } from "lodash";
import * as R from "ramda";
import { BlockRange, Classroom, Day, Instructor, Session } from "@models/ISchema";
import { compareSessions } from "./compareSelected";
import { blocksFormat, intervalsFormat } from "../utils/sessionFormat";
import { LinkPageType, Week, WeeksById } from "../context/linkData.reducer";
import {
  Assignment,
  BlockRanges,
  EditedSession,
  EditedSessions,
  FormPageType,
} from "../context/formData.reducer";
import {
  sameBlocks,
  sameClassrooms,
  sameDays,
  sameInstructors,
  sameIntervals,
  sessionsHaveTheSameResources,
} from "./sessionsHaveTheSameResources";

export const initEditedSessions = (
  selectedSessions: (Session | EditedSession)[],
  link: LinkPageType,
): {
  editedSessions: EditedSessions;
  assignmentSame: Assignment;
} => {
  let editedSession: {
    editedSessions: EditedSessions;
    assignmentSame: Assignment;
  } = null;

  if (selectedSessions.length === 1) {
    editedSession = {
      editedSessions: selectedSessions[0],
      assignmentSame: {
        blocks: true,
        day: true,
        instructors: true,
        classrooms: true,
        intervals: true,
      },
    };
  } else {
    const compare = compareSessions(selectedSessions, link?.weeks ?? []);

    editedSession = {
      editedSessions: compare.editedSessions,
      assignmentSame: compare.assignmentSame,
    };
  }
  return editedSession;
};

/**
 * Given a FormPageType state, return a `sessionToCreate` for each selected
 * day, where each new `sessionToCreate` has one of the selected days selected.
 *
 * @param formState FormPageType
 * @return EditedSession[]
 */
export const sessionsToCreateBySelectedDays = (formState: FormPageType): EditedSession[] => {
  const editedSessions = formState?.editedSessions;
  const selectedSection = formState?.selectedSection;

  if (!editedSessions || !editedSessions?.blocks) return [];

  const { days: selectedDays, startTime, endTime } = editedSessions?.blocks;

  const emptyBlock = (day: Day) => ({
    day,
    days: [day],
    startTime,
    endTime,
    blocks: undefined,
    selected: "hours" as "hours" | "blocks",
  });

  return R.map(
    (day: Day) => ({
      id: uuid(),
      blocks: emptyBlock(day),
      instructors: (editedSessions?.instructors ?? []) as Instructor[],
      classrooms: (editedSessions?.classrooms ?? []) as Classroom[],
      intervals: (editedSessions?.intervals ?? []) as Week[],
      session: null as Session,
      isNew: false,
      isCloned: true,
      section: cloneDeep(selectedSection),
    }),
    selectedDays,
  );
};

/**
 * Given a FormPageType state, returns the sessions to add to the editSessions
 * view.
 *
 * @param formState FormPageType
 * @return EditedSession[]
 */
export const newSessionsToCreateByFormState = (formState: FormPageType): EditedSession[] => {
  const uniqueSessionToSourceFrom = (state: FormPageType): boolean => {
    const noSavedSessions = R.pipe(R.propOr([], "savedSessions"), R.empty);
    const hasOneSelectionSession = R.pipe(R.propOr([], "selectedSessions"), size, R.equals(1));
    return noSavedSessions(state) && hasOneSelectionSession(state);
  };

  const editedSessions = formState?.editedSessions;

  if (uniqueSessionToSourceFrom(formState)) {
    return [
      {
        id: uuid(),
        blocks: R.view(R.lensProp("blocks"), formState?.editedSessions),
        instructors: R.view(R.lensProp("instructors"), formState?.editedSessions),
        classrooms: R.view(R.lensProp("classrooms"), formState?.editedSessions),
        intervals: R.view(R.lensProp("intervals"), formState?.editedSessions),
        session: null as Session,
        isNew: false,
        isCloned: true,
        section: R.view(R.lensPath(["selectedSessions", 0, "section"]), formState),
      },
    ];
  }

  const emptyBlock = {
    day: undefined,
    days: [],
    startTime: undefined,
    endTime: undefined,
    blocks: undefined,
    selected: undefined as "hours" | "blocks",
  };

  return formState?.selectedSessions.map(session => {
    const blocks = editedSessions?.blocks
      ? cloneDeep(editedSessions?.blocks)
      : cloneDeep(emptyBlock);
    return {
      id: uuid(),
      blocks,
      instructors: R.view(R.lensProp("instructors"), formState?.editedSessions),
      classrooms: R.view(R.lensProp("classrooms"), formState?.editedSessions),
      intervals: R.view(R.lensProp("intervals"), formState?.editedSessions),
      session: null as Session,
      isNew: false,
      isCloned: true,
      section: R.view(R.lensProp("section"), session),
    };
  });
};

/**
 * Return true if the given session have at least one week checked and it's
 * blocks defined, false otherwise.
 *
 * @param session EditedSession
 * @return boolean
 */
export const sessionIsSavable = (session: EditedSession): boolean => {
  const toBool = (x: number | string): boolean => !!x;
  const checkedWeeks: (sessions: EditedSession) => Week[] = R.curry(
    R.pipe(
      R.propOr([], "intervals"),
      R.filter(R.has("checked")),
      R.filter(R.propOr(false, "checked")),
    ),
  );
  const hasCheckedWeeks = R.pipe(checkedWeeks, R.length, toBool);
  const hasBlocksDefined = R.pipe(R.propOr({}, "blocks"), R.propOr("", "selected"), toBool);
  return hasCheckedWeeks(session) && hasBlocksDefined(session);
};

/**
 * Return true if all the given sessions have at least one week checked and
 * it's blocks defined.
 *
 * @param session EditedSession[]
 * @return boolean
 */
export const allSessionsCanBeSaved = R.all(sessionIsSavable);

/**
 * Filter the given sessions by the ones that have at least one week checked
 * and their blocks defined.
 *
 * @param session EditedSession[]
 * @return EditedSession[]
 */
export const savableSessionsToCreate = R.filter(sessionIsSavable);

export const getNewEditedSessions = (
  savedSessions: EditedSession[],
  selected: (Session | EditedSession)[],
  link: LinkPageType,
): { editedSessions?: EditedSessions; assignmentSame?: Assignment } => {
  if (!savedSessions.length) return initEditedSessions(selected, link);

  const savedSessionsById = R.reduce((acc, curr) => R.assoc(curr.id, curr, acc), {}, savedSessions);

  // Si la sesión ya había sido guardada compara la sesión guardada
  const selectedSessions = R.map(
    selectedSession => savedSessionsById[selectedSession.id] ?? selectedSession,
    selected,
  );
  const { editedSessions, assignmentSame } = compareSessions(selectedSessions, link?.weeks ?? []);
  return selectedSessions.length ? { editedSessions, assignmentSame } : {};
};

/**
 * Given a `checked`, the sessions of the selected section, the form context
 * and the sectionId, return an array with the new context's selected sessions.
 *
 * @param checked boolean
 * @param sessionsOfSection Session[]
 * @param form FormPageType
 * @return (Session | EditedSession)[]
 */
export const getNewSelectedSessionsBySection = (
  checked: boolean,
  sessionsOfSection: Session[],
  form: FormPageType,
  sectionId: string,
) => {
  const belongsToSelectedSection = R.pipe(
    R.propOr({}, "section"),
    R.propOr("", "id"),
    R.equals(sectionId),
  );
  const selectedSessionsOfOtherSections = R.reject(
    belongsToSelectedSection,
    form?.selectedSessions ?? [],
  );

  return R.not(checked)
    ? selectedSessionsOfOtherSections
    : R.concat(
        R.filter(belongsToSelectedSection, form?.sessionsToCreate ?? []),
        R.concat(sessionsOfSection, selectedSessionsOfOtherSections),
      );
};

/**
 * Given a single session and an array of sessions, return a copy of the given
 * array of sessions with the single session `added` if it wasn't there or
 * `removed` if it was already there.
 *
 * @param selectedSession Session | EditedSession
 * @param currentSelectedSessions (Session | EditedSession)[]
 * @return (Session | EditedSession)[]
 */
export const getNewSelectedSessions = (
  selectedSession: Session | EditedSession,
  currentSelectedSessions: EditedSession[],
) => {
  const selectedSessionsById = R.reduce(
    (acc, session) => R.assoc(session.id, session, acc),
    {},
    currentSelectedSessions,
  );
  return R.has(selectedSession?.id, selectedSessionsById)
    ? R.reject(R.pipe(R.propOr("", "id"), R.equals(selectedSession?.id)), currentSelectedSessions)
    : R.append(selectedSession, currentSelectedSessions);
};

/**
 * Returns an object with the following structure:
 * {
 *   assignmentSame: {
 *     blocks: boolean,
 *     instructors: boolean,
 *     classrooms: boolean,
 *     intervals: boolean,
 *   },
 *   cloneSelected: [],
 *   editedSessions: {
 *     blocks?: ?,
 *     instructors?: ?[],
 *     classrooms?: ?[],
 *     intervals: ?[],
 *   }
 * }
 *
 * ## assignmentSame
 * An object with a boolean for each editable resource. By each resource True
 * if the selected sessions have the same assignment between the resource, false
 * otherwise.
 *
 * ## cloneSelected
 * Array with a clone of the selected sessions.
 *
 * ## editedSessions
 * An object with the common resources between the selected sessions.
 *
 * @param selectedSession Sessio | EditedSession
 * @param form FormPageType
 * @param link LinkPageType
 */
export const validateSession = (
  selectedSession: Session | EditedSession,
  form: FormPageType,
  link: LinkPageType,
) => {
  const newSelectedSessions = getNewSelectedSessions(selectedSession, form?.selectedSessions);
  const { editedSessions, assignmentSame } = getNewEditedSessions(
    form?.savedSessions ?? [],
    newSelectedSessions,
    link,
  );
  return { editedSessions, assignmentSame, cloneSelected: newSelectedSessions };
};

export const validateSavedSession = (
  form: FormPageType,
  link: LinkPageType,
  savedSessions: EditedSession[] = null,
) => {
  return getNewEditedSessions(
    savedSessions ? [...savedSessions] : [],
    form.selectedSessions ? [...form.selectedSessions] : [],
    link,
  );
};

const modifySession = (
  state: FormPageType,
  link: LinkPageType,
  savedSessionsById: { [key: string]: Session | EditedSession },
  sessionsToCreateById: { [key: string]: Session | EditedSession },
) => (session: Session | EditedSession) => {
  const { assignmentEdited, editedSessions } = state;

  const sessionBlocks = (savedSession: EditedSession) => {
    return assignmentEdited.blocks
      ? editedSessions?.blocks
      : savedSession
      ? savedSession?.blocks
      : blocksFormat(session);
  };
  const sessionDay = (savedSession: EditedSession) => {
    return assignmentEdited?.day
      ? editedSessions?.blocks?.day
      : !!savedSession?.blocks?.day
      ? savedSession?.blocks?.day
      : (session as Session)?.assignment?.blockRange?.start?.day ??
        (session as EditedSession)?.blocks?.day;
  };
  const sessionInstructors = (savedSession: EditedSession) => {
    return assignmentEdited?.instructors
      ? editedSessions?.instructors
      : savedSession
      ? savedSession?.instructors
      : session?.instructors;
  };
  const sessionClassrooms = (savedSession: EditedSession) => {
    return assignmentEdited.classrooms
      ? editedSessions?.classrooms
      : savedSession
      ? savedSession?.classrooms
      : session?.classrooms;
  };
  const sessionIntervals = (savedSession: EditedSession) => {
    return assignmentEdited?.intervals
      ? editedSessions?.intervals
      : savedSession
      ? savedSession?.intervals
      : intervalsFormat(session, link?.weeks ?? []);
  };

  const savedSession = savedSessionsById[session.id] ?? sessionsToCreateById[session.id];
  const newBlocks = sessionBlocks(savedSession);

  return {
    ...savedSession,
    id: session?.id,
    blocks: R.pipe(
      R.set(R.lensProp<BlockRanges>("day"), sessionDay(savedSession)),
      R.set(R.lensProp<BlockRanges>("days"), undefined),
    )(newBlocks),
    instructors: sessionInstructors(savedSession),
    classrooms: sessionClassrooms(savedSession),
    intervals: sessionIntervals(savedSession),
    session: session as Session,
  };
};

export const handleSavedSessions = (state: FormPageType, link: LinkPageType) => {
  // define utility functions
  const sessionsById = (
    table: { [key: string]: Session | EditedSession },
    session: Session | EditedSession,
  ) => R.assoc(session.id, session, table);
  const isSavedSession = (session: Session | EditedSession) =>
    !session?.id?.includes("-") || (session as EditedSession)?.isNew;

  const [savedSessionsById, sessionsToCreateById] = R.ap(
    [R.reduce(sessionsById, {})],
    [state?.savedSessions ?? [], state?.sessionsToCreate ?? []],
  );

  // define the modified and unmodified sessions
  const modifiedSessions = R.pipe(
    R.propOr([], "selectedSessions"),
    R.map(modifySession(state, link, savedSessionsById, sessionsToCreateById)),
    R.reject((session: EditedSession) => {
      if (session.id in savedSessionsById) {
        return (
          sessionsHaveTheSameResources(session, session?.session) &&
          sessionsHaveTheSameResources(session, savedSessionsById[session.id])
        );
      } else {
        return sessionsHaveTheSameResources(session, session?.session);
      }
    }),
  )(state);
  const modifiedSessionsById = R.reduce(sessionsById, {}, modifiedSessions);
  const unmodifiedSessions = R.reject(
    R.pipe(R.propOr("-1", "id"), R.has(R.__, modifiedSessionsById)),
    state?.savedSessions ?? [],
  );

  // merge the modified and unmodified sessions in one array. Then, define the
  // `editedSessions` and `assignmentSame` objects for the context
  const newSavedSessions = R.concat(unmodifiedSessions, modifiedSessions);
  const saveValidation = validateSavedSession(state, link, newSavedSessions);

  const clonedSessionsById = R.reduce(sessionsById, {}, newSavedSessions);

  // since `naturalSessions` is used for the context's `savedSession`, we have
  // to remove the sessions that aren't modified in relation to what is saved on the DB.
  const naturalSessions = R.pipe(
    R.filter(isSavedSession),
    R.reject((session: EditedSession) => sessionsHaveTheSameResources(session, session?.session)),
  )(newSavedSessions);
  const sessionsToCreate = state?.sessionsToCreate?.map(sessionToCreate => {
    return sessionToCreate?.id in clonedSessionsById
      ? clonedSessionsById[sessionToCreate?.id]
      : sessionToCreate;
  });

  // define object with the ids of the session to create as keys
  const savedSessionsToCreateIds = newSavedSessions
    .filter(isSavedSession)
    .reduce((acc, s) => R.assoc(s?.id, true, acc), {});

  return {
    saveValidation,
    naturalSessions,
    sessionsToCreate,
    savedSessionsToCreateIds,
  };
};

const sessionsEqualityByResource = (
  sessions: (Session | EditedSession)[],
  link: LinkPageType,
): Assignment => {
  const blocksEquality = R.pipe(
    R.map(blocksFormat),
    R.reduce(
      ({ eq, prev }, curr) => ({
        eq: !eq || !prev ? eq : sameBlocks(prev, curr),
        prev: curr,
      }),
      { eq: true, prev: null },
    ),
    R.prop("eq"),
  );
  const dayEquality = R.pipe(
    R.map(blocksFormat),
    R.map(R.propOr<BlockRange>(null, "day")),
    R.reduce(
      ({ eq, prev }, curr) => ({
        eq: !eq || !prev ? eq : sameDays(prev, curr as any),
        prev: curr,
      }),
      { eq: true, prev: null },
    ),
    R.prop("eq"),
  );
  const instructorsEquality = R.pipe(
    R.map(R.propOr([], "instructors")),
    R.reduce(
      ({ eq, prev }, curr) => ({
        eq: !eq || !prev ? eq : sameInstructors(prev, (curr as unknown) as Instructor[]),
        prev: curr,
      }),
      { eq: true, prev: null },
    ),
    R.prop("eq"),
  );
  const classroomsEquality = R.pipe(
    R.map(R.propOr([], "classrooms")),
    R.reduce(
      ({ eq, prev }, curr) => ({
        eq: !eq || !prev ? eq : sameClassrooms(prev, (curr as unknown) as Classroom[]),
        prev: curr,
      }),
      { eq: true, prev: null },
    ),
    R.prop("eq"),
  );
  const intervalsEquality = R.pipe(
    R.map(R.flip(intervalsFormat)(link?.weeks ?? []) as any),
    R.reduce(
      ({ eq, prev }, curr) => ({
        eq: !eq || !prev ? eq : sameIntervals(prev, (curr as unknown) as Week[]),
        prev: curr,
      }),
      { eq: true, prev: null },
    ),
    R.prop("eq"),
  );

  return R.applySpec<Assignment>({
    blocks: blocksEquality,
    day: dayEquality,
    instructors: instructorsEquality,
    classrooms: classroomsEquality,
    intervals: intervalsEquality,
  })(sessions);
};

/**
 * Returns an object with the following structure:
 * {
 *   newSelectedSessions: (Session | EditedSession)[],
 *   newAssignmentSame: Assignment
 * }
 */
export const handleToggleAllSessionsSelection = (
  formState: FormPageType,
  link: LinkPageType,
  selectAll: boolean,
): {
  newAssignmentSame: Assignment;
  newSelectedSessions: (Session | EditedSession)[];
} => {
  if (R.not(selectAll)) {
    return {
      newSelectedSessions: [],
      newAssignmentSame: {
        blocks: true,
        day: true,
        instructors: true,
        classrooms: true,
        intervals: true,
      },
    };
  }

  const savedSessionsById = R.reduce(
    (acc, session) => R.assoc(R.prop("id", session), session, acc),
    {},
    formState?.savedSessions,
  );

  const allSessions = R.pipe(
    R.propOr([], "sections"),
    R.map(R.pipe(R.pick(["sessions", "unasignedSessions"]), R.toPairs, R.map(R.last))),
    R.flatten,
    R.map<Session | EditedSession, Session | EditedSession>(session =>
      R.prop("id", session) in savedSessionsById
        ? savedSessionsById[R.prop("id", session)]
        : session,
    ),
    R.concat(formState?.sessionsToCreate ?? []),
  )(link);

  return {
    newSelectedSessions: allSessions,
    newAssignmentSame: sessionsEqualityByResource(allSessions, link),
  };
};

const getWeeksByIds = (weeks: Week[], removedWeeks: string[]) => {
  return weeks?.reduce(
    (acc, week) => {
      const getClonedWeek = (isChecked: boolean): WeeksById =>
        structuredClone({
          [week.id]: {
            ...week,
            checked: isChecked,
          },
        });

      return {
        removedWeeksById: {
          ...acc.removedWeeksById,
          ...getClonedWeek(removedWeeks?.includes(week.id)),
        },
        newWeeksById: {
          ...acc.newWeeksById,
          ...getClonedWeek(!removedWeeks?.includes(week.id) && !!week?.checked),
        },
      };
    },
    {
      removedWeeksById: {} as WeeksById,
      newWeeksById: {} as WeeksById,
    },
  );
};

/**
 * Given a FormPageType state and a Week[] array, return an EditedSession[]
 * array taking the deleted weeks of the first selectedSession assigning them
 * to the new sessions.
 *
 * Time complexity: O(n)
 * Where `n` is the number of weeks in the `linkWeeks` array.
 *
 * @param formState FormPageType
 * @param linkWeeks Week[]
 * @return EditedSession[]
 */
export const forkSelectedSessions = (
  formState: FormPageType,
): {
  newSessions: EditedSession[];
  savedSessions: EditedSession[];
} => {
  const selectedSessions = [...(formState?.selectedSessions ?? [])];
  const firstSelectedSession = selectedSessions.find((session: Session | EditedSession) => {
    return !session.id.includes("-");
  });
  const firstSelectedSessionId = firstSelectedSession?.id ?? "";

  const removedWeeks = formState?.removedWeekIdsBySessionId[firstSelectedSessionId] as string[];

  const { removedWeeksById, newWeeksById } = getWeeksByIds(
    formState?.editedSessions?.intervals,
    removedWeeks,
  );
  const allWeeksArr = Object.values(newWeeksById);

  const forkedWeeks = allWeeksArr?.filter(week => !!week.checked);
  const weeksToForkById = forkedWeeks?.reduce(
    (acc, week) => ({
      ...acc,
      [week.id]: true,
    }),
    {},
  );

  const forkedWeeksByOriginSessions = formState?.selectedSessions?.reduce(
    (acc: { [key: string]: string[] }, session) => {
      return {
        ...acc,
        [session.id]: Object.keys(weeksToForkById),
      };
    },
    {},
  );

  const newSessions = [];
  const savedSessions = [];

  formState?.savedSessions?.forEach(session => {
    newSessions.push({
      ...session?.session,
      ...session,
      session: null,
      intervals: allWeeksArr,
      assignment: {
        intervals: forkedWeeks,
      },
      id: uuid(),
      isCloned: true,
      forkedWeeksByOriginSessions,
    });

    if (!sessionsHaveTheSameResources(session, session?.session)) {
      savedSessions.push({
        ...session?.session,
        session: session?.session,
        intervals: Object.values(removedWeeksById),
      });
    }
  });

  return {
    newSessions,
    savedSessions,
  };
};
