import React, { useState, useEffect, useContext, Fragment, useCallback } from "react";
import * as R from "ramda";
import dayjs from "dayjs";
import cx from "classnames";
import { Button, CardState, Loading, Weekly } from "@foris/avocado-ui";
import { useWarnings } from "../../hooks/useWarnings";
import { AppContext } from "../../context/EditSessionsContext";
import { Week, Types as LinkTypes } from "../../context/linkData.reducer";
import { EditedSession, FormPageType, Types } from "../../context/formData.reducer";
import FormItem from "../FormEdit/FormItem";
import WeeksMultiSelect from "../../../components/WeeksMultiSelect";
import { useGetTermPartsByCategory } from "../../../hooks/useGetTermPartsByCategory";
import { TermPartsByCategory } from "@modules/sections/ISections";
import { isOutsideThreshold } from "../../utils/isOutsideThreshold";
import { useLazyQuery } from "react-apollo";
import { BLOCKING_THRESHOLD_QUERY } from "../../graphql/blockingThreshold.query";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { ContextEDH } from "@context/ContextEDH";
import css from "./repeat.module.scss";

export interface IWeeklyItem {
  id: string;
  label: string;
  disabled: boolean;
  state?: "active" | "current";
  tooltip: { label: string };
  onClick: (item: IWeeklyItem) => void;
  highlight?: boolean;
}

interface IRepeatProps {
  originalWeeksBySessionId: { [key: string]: string[] };
  disabled?: boolean;
}

const getUpdatedIntervals = (intervals: Week[], weeks: { [id: string]: { checked: boolean } }) => {
  return intervals.map(interval => {
    const week = weeks && weeks?.[interval?.id] ? weeks[interval.id] : null;

    if (!!week) {
      return {
        ...interval,
        checked: week?.checked,
      };
    }

    return interval;
  });
};

const Repeat: React.FC<IRepeatProps> = ({ originalWeeksBySessionId, disabled = false }) => {
  const { scenario }: IParams = useParams();
  const { state: stateEDH } = useContext(ContextEDH);
  const { state, dispatch } = useContext(AppContext);
  const [weeksByYear, setWeeksByYear] = useState(null);
  const [hasThreshold, setHasThreshold] = useState(false);
  const [warnings, setWarnings] = useWarnings(
    {
      numberOfSelectedWeeks: {
        message: "Debes seleccionar al menos una semana de la lista.",
        active: false,
        predicate: (form: FormPageType) => {
          return R.none(R.propOr(false, "checked"), form?.editedSessions?.intervals ?? []);
        },
      },
    },
    form => !form?.savedSessionsToCreateIds && !form?.selectedSessions?.length,
  );
  const weeks = state?.form?.editedSessions?.intervals;
  const errors = state?.form?.errors;
  const thresholdDate = state?.form?.blockingThreshold;

  const [loadBlockingThreshold, { loading: isLoadingThreshold }] = useLazyQuery(
    BLOCKING_THRESHOLD_QUERY,
    {
      onCompleted(data) {
        if (hasThreshold) {
          dispatch({
            type: Types.SetBlockingThreshold,
            payload: data?.data?.blockingThreshold?.current_datetime ?? null,
          });
        } else {
          setHasThreshold(!!data?.data?.blockingThreshold?.current_datetime);
        }
      },
    },
  );

  const handleLoadBlockingThreshold = async () => {
    loadBlockingThreshold({
      variables: {
        scenarioId: scenario,
      },
    });
  };

  const [termPartsByCategory, getTermPartsByCategory] = useGetTermPartsByCategory({
    onMount: () => (termPartsByCategory: TermPartsByCategory[]) => {
      if (!state?.link?.termPartsByCategory?.length && termPartsByCategory?.length) {
        dispatch({ type: LinkTypes.TermPartsByCategory, payload: termPartsByCategory });
      }
    },
    linkId: state?.link?.info?.id,
  });

  const someDeletedSessionIsSelected = R.pipe(
    R.map(R.propOr("-", "id")),
    R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
  )(state?.form?.selectedSessions);

  useEffect(() => {
    getTermPartsByCategory();
    handleLoadBlockingThreshold();
  }, []);

  /**
   * Handle Week's form validations
   */
  useEffect(() => {
    setWarnings(state?.form);

    const selectedSessions = R.filter(
      (session: EditedSession) => R.not(session?.id?.includes("-")),
      state?.form?.selectedSessions,
    );

    const currentIntervalIds = R.pipe(
      R.filter(R.propOr(false, "checked")),
      R.map(R.propOr("", "id")),
    )(state?.form?.editedSessions?.intervals ?? []);

    const newRemovedWeekIdsBySessionId = R.reduce(
      (acc, session) => {
        const originalWeekIds = originalWeeksBySessionId[session.id] ?? [];
        return R.assoc(session.id, R.difference(originalWeekIds, currentIntervalIds), acc);
      },
      {},
      selectedSessions,
    );

    dispatch({ type: Types.SetRemovedWeekIdsBySessionId, payload: newRemovedWeekIdsBySessionId });
  }, [state?.form?.editedSessions?.intervals]);

  const handleWeekCheckboxClick = useCallback(
    (clickedWeek: Week) => () => {
      const intervals = getUpdatedIntervals(weeks, {
        [clickedWeek.id]: { checked: !clickedWeek.checked },
      });

      dispatch({ type: Types.IntervalsEditedSessions, payload: { intervals } });
    },
    [weeks, thresholdDate],
  );

  const handleSetWeeksByYear = useCallback(
    (cb?: (weeks: Week[], weeksToDisable: any) => void) => {
      const newWeeksByYear: { [key: string]: Week[] } = weeks?.reduce((acc, week) => {
        const year = dayjs(week.startingDate).year();
        if (year in acc) acc[year].push(week);
        else acc[year] = [week];
        return acc;
      }, {});

      const weeksToDisableCheck = {};

      const res = R.toPairs(newWeeksByYear)?.reduce((acc, [year, itemWeeks]) => {
        const weeklyItems = itemWeeks.map((week, idx) => {
          const canEditPastSessions = stateEDH?.base?.base?.user?.abilities?.can_edit_past_sessions;
          const isOutsideThresholdDate =
            thresholdDate && hasThreshold
              ? isOutsideThreshold(thresholdDate, week, state?.form?.editedSessions?.blocks)
              : false;

          const isWeekDisabled =
            someDeletedSessionIsSelected ||
            !week?.isInTerm ||
            disabled ||
            (isOutsideThresholdDate && !canEditPastSessions);

          if (isOutsideThresholdDate && week?.checked) {
            weeksToDisableCheck[week.id] = { checked: false };
          }

          return {
            id: week.id,
            label: R.add(idx, 1),
            disabled: isWeekDisabled,
            state: week.checked ? "active" : "current",
            tooltip: { label: `${week.name} | ${week.startingDate} ${week.endingDate}` },
            onClick: !isWeekDisabled ? handleWeekCheckboxClick(week) : () => null,
            highlight: !!week.highlight,
          };
        });
        return R.append({ year, weeklyItems }, acc);
      }, []);

      cb?.(weeks, weeksToDisableCheck);
      setWeeksByYear(res);
    },
    [weeks, thresholdDate],
  );

  useEffect(() => {
    handleSetWeeksByYear();
  }, [weeks]);

  useEffect(() => {
    if (thresholdDate) {
      handleSetWeeksByYear((weeks, weeksToDisableCheck) => {
        dispatch({
          type: Types.IntervalsEditedSessions,
          payload: { intervals: getUpdatedIntervals(weeks, weeksToDisableCheck) },
        });
      });
    }
  }, [thresholdDate]);

  /**
   * Transform the `highlight` into `checked` in the context's intervals.
   */
  const applySelectionOnContext = (weeksToModify: { [key: number]: boolean }, checked: boolean) => {
    if (!weeks?.length) return;
    const intervals = R.map(
      R.when(
        R.pipe(R.prop<"id", string>("id"), R.flip(R.has)(weeksToModify)),
        R.pipe(
          R.set(R.lensProp<Week>("checked"), checked),
          R.set(R.lensProp<Week>("highlight"), false),
        ),
      ),
      weeks,
    );
    dispatch({ type: Types.IntervalsEditedSessions, payload: { intervals } });
  };

  /**
   * Highlight all `intervals` whose `id` is in the `weekIdsToHighlight`
   * object.
   */
  const highlightSelectionOnContext = (weekIdsToHighlight: { [key: number]: boolean }) => {
    if (!weeks?.length) return;
    const intervals = R.map(
      R.ifElse(
        R.pipe(R.prop<"id", string>("id"), R.flip(R.has)(weekIdsToHighlight)),
        R.set(R.lensProp<Week>("highlight"), true),
        R.set(R.lensProp<Week>("highlight"), false),
      ),
      weeks,
    );
    dispatch({ type: Types.IntervalsEditedSessions, payload: { intervals } });
  };

  /**
   * Set all interval's highlight to the given param
   */
  const setWeeksHighlight = (highlight: boolean) => {
    if (!weeks?.length) return;
    dispatch({
      type: Types.IntervalsEditedSessions,
      payload: {
        intervals: weeks?.map(week => ({
          ...week,
          highlight: highlight && week?.isInTerm,
        })),
      },
    });
  };

  return (
    <FormItem title="Repetición" type="intervals">
      {isLoadingThreshold && <Loading />}

      {hasThreshold && (
        <div className={css.repeatActions}>
          <Button
            className={css.repeatActions_actionButton}
            onClick={handleLoadBlockingThreshold}
            disabled={isLoadingThreshold}
          >
            Desmarcar semanas
          </Button>
          para ocurrencias bloqueadas por el sistema
        </div>
      )}

      <section className={css.repeat}>
        <WeeksMultiSelect
          disabled={someDeletedSessionIsSelected || disabled}
          applySelectionOnContext={applySelectionOnContext}
          highlightSelectionOnContext={highlightSelectionOnContext}
          onAllWeeksSelected={() => setWeeksHighlight(true)}
          onOpenTermPartsSelector={() => setWeeksHighlight(false)}
          weeks={weeks}
          termPartsByCategory={termPartsByCategory}
        />
        <section className={cx(css.item, css.repeat__weekly__container)}>
          {weeksByYear?.map(({ year, weeklyItems }) => (
            <div key={year} className={css.repeat__weekly}>
              <label className={css.repeat__weekly__label}>{year}</label>
              <Weekly className={css.repeat__weekly__weeks} weeklyItems={weeklyItems} />
            </div>
          ))}
          {errors?.map((error, index) => (
            <Fragment key={index}>
              {error.type === "Intervals" && (
                <CardState
                  typeCard="error"
                  key={index}
                  title="Error de validación"
                  className={css.errorBox}
                >
                  <p className={css.errorBox_text}>{error.message}</p>
                </CardState>
              )}
            </Fragment>
          ))}
        </section>
      </section>
      {warnings.map(warning => (
        <div key={warning} className={css.warning}>
          <CardState typeCard="warning" key={warning} title="Error de validación">
            <p className={css.warning_text}>{warning}</p>
          </CardState>
        </div>
      ))}
    </FormItem>
  );
};

export default Repeat;
